<template>
    <div class="outerBox" @click="handleClick">
		<img src="@/assets/page5/longButton2x.png" class="bgp" alt="" :style="{backgroundColor: selected?'rgba(255, 208, 59, 0.6)':'transparent'}">
		<span class="itemName">{{itemName}}</span>
		<span class="itemValue">{{value}}</span>
	</div>
</template>

<style lang="less" scoped>
.outerBox {
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	z-index: 0;

	.bgp {
		position: absolute;
		width: 100%;
		height: 100%;
	}

	.itemName {
		position: relative;
		margin-left: 6.52%;
		line-height: 2.04vh;
		font-size: 1.3vh;
		font-family: 'pingfangSc', sans-serif;
		font-weight: 400;
		color: #fff;
		z-index: 99;
	}
	.itemValue {
		position: relative;
		font-size: 1.48vh;
		line-height: 1.67vh;
		margin-right: 6.52%;
		font-family: 'titleFont', sans-serif;
		background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		z-index: 99;
	}

}
</style>

<script>
export default {
	props:['itemName', 'value','selected'],
	data(){
		return {
			
		}
	},
	mounted(){
		return false;
	},
	methods:{
		handleClick(){
			this.$emit('click');
		},

	},
	updated(){
	}
}
</script>

